/* eslint-disable arrow-body-style */
import React from 'react'
import styled from 'styled-components'
import { Link, graphql, useStaticQuery } from 'gatsby'

// Components
import Svg from 'components/svg/MemberSvgSmall'

// Images
import ArrowRight from 'img/arrow-right.inline.svg'
import { getPrefix } from 'utils'

const Member = styled.div`
  & h5 {
    font-size: ${(props) => props.theme.font.size['2xl']};
  }
  & h6 {
    font-size: ${(props) => props.theme.font.size.sm};
  }
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-color: #e9f9ff;
    height: 12px;
    width: 100%;
    top: 0;
  }

  @media screen and (max-width: 500px) {
    &::before {
      height: 15px;
    }
  }

  @media screen and (max-width: 480px) {
    &::before {
      height: 20px;
    }
  }

  @media screen and (max-width: 460px) {
    &::before {
      height: 23px;
    }
  }

  @media screen and (max-width: 448px) {
    &::before {
      height: 26px;
    }
  }

  @media screen and (max-width: 435px) {
    &::before {
      height: 28px;
    }
  }

  @media screen and (max-width: 429px) {
    &::before {
      height: 30px;
    }
  }

  @media screen and (max-width: 421px) {
    &::before {
      height: 34px;
    }
  }

  @media screen and (max-width: 407px) {
    &::before {
      height: 38px;
    }
  }

  @media screen and (max-width: 393px) {
    &::before {
      height: 40px;
    }
  }

  @media screen and (max-width: 387px) {
    &::before {
      height: 44px;
    }
  }

  @media screen and (max-width: 373px) {
    &::before {
      height: 48px;
    }
  }
`

const StyledSvg = styled(Svg)`
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  width: 100%;
  overflow: visible;
`

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.font.size.xl};
  & svg {
    width: 15px;
    height: 15px;
  }
`

interface TeamProps {
  limit?: number
  location: any
}

const Team: React.FC<TeamProps> = ({ limit = 0, location }) => {
  const {
    allWpMember: { nodes: team },
  } = useStaticQuery<GatsbyTypes.fetchTeamQuery>(graphql`
    query fetchTeam {
      allWpMember {
        nodes {
          id
          title
          uri
          menuOrder
          acfFields {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 60, width: 435, placeholder: NONE)
                }
              }
            }
            name
            position
            description
            linkedin
            fieldGroupName
          }
        }
      }
    }
  `)

  const visibleTeamMembers = limit === 0 ? team : team.slice(0, limit)


  return (
    <section className="container">
      <div className="row">
        {visibleTeamMembers.map((member: any, index: number) =>
          member.menuOrder === null ? (
            <>
              <div className="col-6 col-lg-3 mb-4" key={index}>
                <Member className="d-flex flex-column align-items-center">
                  <StyledSvg
                    index={index}
                    image={
                      member.acfFields?.image?.localFile?.childImageSharp
                        ?.gatsbyImageData.images.fallback.src
                    }
                    className="mb-3"
                  />
                  <h5 className="text-center text-tertiary font-family-tertiary mb-2">
                    {member?.acfFields?.name}
                  </h5>
                  <h6 className="text-tertiary text-uppercase mb-2">
                    {member.acfFields.position}
                  </h6>
                  <StyledLink
                    to={`${getPrefix(location)}${member.uri}`}
                    className="text-secondary font-family-secondary"
                  >
                    Meer <ArrowRight className="ml-2" />
                  </StyledLink>
                </Member>
              </div>
            </>
          ) : (
            <>
              <div className="col-6 col-lg-3 mb-4" key={index}>
                <Member className="d-flex flex-column align-items-center">
                  <StyledSvg
                    index={index}
                    image={
                      member.acfFields?.image?.localFile?.childImageSharp
                        ?.gatsbyImageData.images.fallback.src
                    }
                    className="mb-3"
                  />
                  <h5 className="text-center text-tertiary font-family-tertiary font-weight-black mb-2">
                    {member?.acfFields?.name}
                  </h5>
                  <h6 className="text-tertiary text-uppercase mb-2">
                    {member.acfFields.position}
                  </h6>
                  <StyledLink
                    to={`${getPrefix(location)}${member.uri}`}
                    className="text-secondary font-family-secondary"
                  >
                    Meer <ArrowRight className="ml-2" />
                  </StyledLink>
                </Member>
              </div>
            </>
          )
        )}
      </div>
    </section>
  )
}
export default Team
