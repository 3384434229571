import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import BackgroundImage from 'components/shared/BackgroundImage'
import Button from 'components/elements/Button'
import Svg from 'components/svg/MemberSvgBig'
import Team from 'components/flex/Team'

// Images
import LinkedinIcon from 'img/linkedin.inline.svg'

const Wrapper = styled.section`
  min-height: 800px;
`

const BannerWrapper = styled.div`
  position: relative;
  height: 300px;

  & h1 {
    color: ${(props) => props.theme.colors.secondary};
    font-size: ${(props) => props.theme.font.size['5xl']};
  }

  & h6 {
    color: ${(props) => props.theme.colors.primary};
  }
`

const MemberWrapper = styled.div`
  margin-top: -270px;
`

const Social = styled.div`
  & button {
    font-size: ${(props) => props.theme.font.size.xl};

    & a {
      width: 100% !important;
    }
    & span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100% !important;
    }
  }

  width: 50%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const LinkedinWrapper = styled.div`
  & svg {
    width: 30px;
    height: 30px;
    & path {
      fill: ${(props) => props.theme.colors.secondary};
    }
  }
`

const MemberInfo = styled.div`
  & h1,
  & h5 {
    color: ${(props) => props.theme.colors.tertiary} !important;
  }

  @media screen and (max-width: 575px) {
    & h1 {
      font-size: 30px;
    }
  }
`
interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      // eslint-disable-next-line camelcase
      fields: GatsbyTypes.WpMember_Acffields
    }
  }
  pageContext: {
    type: 'ecd' | 'pfz'
  }
  location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    page: { title, seo, fields },
  },
  pageContext: { type },
  location,
}) => (
  <Layout type={type}>
    <SEO seo={seo} />
    <Wrapper>
      <BannerWrapper>
        <BackgroundImage image={fields?.background} alt="alt" />
      </BannerWrapper>
      <MemberWrapper className="container position-relative">
        <div className="row">
          <div className="col-lg-5 d-lg-block d-flex flex-wrap justify-content-center mt-n4">
            <Svg
              image={fields?.image?.localFile?.publicURL}
              className="position-relative z-index-3"
            />
            <Social className="d-flex flex-column pb-lg-0 pb-4 ml-lg-5">
              <Button
                to={`/${type === 'ecd' ? 'ecd' : 'pluriform-zorg'}/contact`}
                className="secondary filled button my-4 py-1"
                arrow
              >
                Neem contact op
              </Button>
              <Button
                to={fields?.linkedin || '#'}
                className="secondary d-inline-flex justify-content-between"
              >
                Bekijk profiel
                <LinkedinWrapper>
                  <LinkedinIcon />
                </LinkedinWrapper>
              </Button>
            </Social>
          </div>
          <div className="col-lg-7 position-relative z-index-3">
            <MemberInfo className="card">
              <h1>{fields?.name ? fields?.name : title}</h1>
              <h5 className="text-uppercase">{fields?.position}</h5>
              <ParseContent content={fields?.description} />
            </MemberInfo>
          </div>
        </div>
      </MemberWrapper>
    </Wrapper>
    <div className="my-5">
      <h2 className="text-tertiary text-center">De rest van ons team</h2>

      <Team location={location} />
    </div>
  </Layout>
)
export const pageQuery = graphql`
  query memberEcdById($id: String!) {
    page: wpMember(id: { eq: $id }) {
      id
      title
      fields: acfFields {
        image {
          localFile {
            publicURL
          }
        }
        background {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: NONE)
            }
          }
        }
        name
        position
        description
        linkedin
        fieldGroupName
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default PageTemplate
