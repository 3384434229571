import React from 'react'

function Icon({ image, className = '' }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="337.922"
      height="409"
      viewBox="0 0 337.922 409"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="none"
            d="M0 238.5a162.081 162.081 0 0165.726-130.4V0h180.088v98.512A162.314 162.314 0 01326 238.5c0 89.748-72.978 162.5-163 162.5S0 328.248 0 238.5z"
            transform="translate(-.351)"
          />
        </clipPath>
        <pattern
          id="pattern"
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          viewBox="0 0 267 403"
        >
          <image width="267" height="403" xlinkHref={image} />
        </pattern>
      </defs>
      <g data-name="Group 1" transform="translate(-168.539 -112)">
        <ellipse
          cx="163.961"
          cy="163.5"
          fill="#fff"
          stroke="#e70087"
          strokeMiterlimit="10"
          strokeWidth="10"
          rx="163.961"
          ry="163.5"
          transform="translate(173.539 189)"
        />
        <g transform="translate(174.851 112)">
          <path
            fill="none"
            d="M0 238.5a162.081 162.081 0 0165.726-130.4V0h180.088v98.512A162.314 162.314 0 01326 238.5c0 89.748-72.978 162.5-163 162.5S0 328.248 0 238.5z"
            data-name="Mask"
            transform="translate(-.351)"
          />
          <g clipPath="url(#clip-path)" data-name="Geert">
            <path
              fill="url(#pattern)"
              d="M0 0H267V403H0z"
              data-name="Geert"
              transform="translate(48.649 14)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Icon
