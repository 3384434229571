import React from 'react'

function Icon({ index, image, className = '' }: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="218.474"
      height="265.5"
      viewBox="0 0 218.474 265.5"
      className={className}
      id={image}
    >
      <defs>
        <clipPath id="clip-path-2">
          <path
            fill="none"
            d="M0 155.236A105.542 105.542 0 0142.742 70.36V0h117.112v64.118A105.848 105.848 0 110 155.236z"
            transform="translate(-.117)"
          />
        </clipPath>
        <pattern
          id={`image-${index}`}
          width="100%"
          height="100%"
          preserveAspectRatio="none"
          viewBox="0 0 233 350"
        >
          <image key={image} width="233" height="350" xlinkHref={image} />
        </pattern>
      </defs>
      <g data-name="Group 3" transform="translate(-463.763 -537)">
        <ellipse
          cx="106.737"
          cy="106.5"
          fill="none"
          stroke="#e70087"
          strokeMiterlimit="10"
          strokeWidth="5"
          rx="106.737"
          ry="106.5"
          transform="translate(466.263 587)"
        />
        <g transform="translate(467.117 537)">
          <path
            fill="none"
            d="M0 155.236A105.542 105.542 0 0142.742 70.36V0h117.112v64.118A105.848 105.848 0 110 155.236z"
            data-name="Mask"
            transform="translate(-.117)"
          />
          <g clipPath="url(#clip-path-2)" data-name="Geert">
            <path
              fill={`url(#image-${index})`}
              d="M0 0H174V262H0z"
              data-name="Geert"
              transform="translate(31.883 9)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Icon
